import React, { useState, useEffect } from 'react';
import { getApi } from '../utility/Utility';
import Day from './Day';

function Forecast() {

	const [forecastWeather, setForecastWeather] = useState(0);

	useEffect(() => {
		const apiUrl = 'https://api.banjaluka-vrijeme.com/';
		getApi(`${apiUrl}forecast-weather?location_name=Banja+Luka`).then(data => {
			if (data) {
				setForecastWeather(data);
			}
		});

	}, []);

	if (!forecastWeather) {
		return '';
	}

	return (
		<div className="Forecast">
			{forecastWeather.map((object, i) => <Day dayForecast={object} key={i} />)}
		</div>
	);
}

export default Forecast;
