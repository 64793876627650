import React, { useState, useEffect } from 'react';
import {addZero, getDayText, getMonthText } from '../utility/Utility';

function Location() {

	const [date, setDate] = useState('');

	useEffect(() => {
		const localDate = new Date().toLocaleString("en-US", { timeZone: "Europe/Sarajevo" });
		const d = new Date(localDate);
		const currentDay = getDayText(d);
		const currentMonth = getMonthText(d);
		const dayInMonth = addZero(d.getDate());

		setDate(`${currentDay}, ${dayInMonth} ${currentMonth}`);

	}, []);

	return (
		<div className="column">
			<div className="date">{date}</div>
			<div className="city">Banja Luka</div>
		</div>
	);
}

export default Location;
