export const addZero = val => {
	return (val <= 9) ? ("0" + val) : val;
}

export const getApi = url => new Promise(resolve => {
	fetch(url, {
		method: 'GET',
	})
		.then(responseRaw => responseRaw.json())
		.then(responseJson => { resolve(responseJson); })
		.catch(error => { console.info(`Something went wrong ${error}`); });
});

export const todayDate = (timezone = 'Europe/Sarajevo') => {
	const date = new Date().toLocaleString("sr-RS", { timeZone: timezone });
	let d = date.split(' ');
	d = d[0].split('.');

	return `${d[2]}-${addZero(d[1])}-${addZero(d[0])}`;
}

export const getScreenOrientation = () => {
	if (window.matchMedia("(orientation: portrait)").matches) {
		return 'portrait';
	}

	return 'landscape';
}

export const getMonthText = (date) => {
	const d = new Date(date);
	const months = ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'August', 'Septembar', 'Octobar', 'Novembar', 'Decembar'];
	return months[d.getMonth()];
}

export const getDayText = (date) => {
	const d = new Date(date);
	const days = ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'];
	return days[d.getDay()];
}

export const getDayInMonth = (date) => {
	const d = new Date(date);
	return addZero(d.getDate());
}
