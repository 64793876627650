import React, { useState, useEffect } from 'react';
import { landscapeImages, portraitImages } from './images/Images';

function Background(props) {
	const getBackgrund = params => {
		if (params.isDay === undefined || params.isDay === null) {
			return null;
		}

		const imageTypes = {
			landscape: {
				'day': landscapeImages['day'][Math.floor(Math.random() * landscapeImages['day'].length)],
				'night': landscapeImages['night'][Math.floor(Math.random() * landscapeImages['night'].length)]
			},
			portrait: {
				'day': portraitImages['day'][Math.floor(Math.random() * portraitImages['day'].length)],
				'night': portraitImages['night'][Math.floor(Math.random() * portraitImages['night'].length)]
			},
		}

		let toReturn = imageTypes[params.screenOrientation]['day'];

		if (!params.isDay) {
			toReturn = imageTypes[params.screenOrientation]['night'];
		}

		return toReturn.toString();
	}
	
	useEffect(() => {
		const bgd = getBackgrund(props);
		if (bgd) {
			setBg(bgd);
		}
	}, [props]);

	const [bg, setBg] = useState(null);

	return (
		<div className={bg ? 'Background show' : 'Background'} style={bg ? { backgroundImage: `url(${bg})` } : {}} />
	);
}

export default Background;
