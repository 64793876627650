import React from 'react';
import Location from '../location/Location';

function Weather(props) {

	if (props.icon === undefined || props.icon === null) {
		return null;
	}

	return (
		<div className="Weather">

			<Location />

			<div className="row">
				<div className="icon"> <img src={props.icon} alt={props.conditionText} title={props.conditionText} /> </div>
				<div className="current-temp" title={props.updated ? "Temperatura ažurirana poslednji put: "+ props.updated : ''}>{props.current}°</div>
				<div className="column conditions">
					<div>Padavine: <b>{props.rain}%</b></div>
					<div>Vlažnost: <b>{props.humidity}%</b></div>
					<div>Vjetar: <b>{props.wind} km/h</b></div>
				</div>
			</div>
		</div>
	);
}

export default Weather;
