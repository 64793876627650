import React, { useState, useEffect } from 'react';
import Background from './components/background/Background';
import Weather from './components/weather/Weather';
import Forecast from './components/forecast/Forecast';
import { getApi, getScreenOrientation, todayDate } from './components/utility/Utility';
import './App.css';

function App() {

	const [screenOrientation, setScreenOrientation] = useState('landscape');
	const [currentWeather, setCurrentWeather] = useState(0);
	const [todayForecast, setTodayForecast] = useState(0);

	useEffect(() => {
		const apiUrl = 'https://api.banjaluka-vrijeme.com/';
		getApi(`${apiUrl}current-weather?location_name=Banja+Luka`).then(data => {
			setCurrentWeather(data);
			const o = getScreenOrientation();
			setScreenOrientation(o);
		});

		const d = todayDate();
		getApi(`${apiUrl}forecast-weather?date=${d}&location_name=Banja+Luka`).then(data => {
			if (data) {
				return setTodayForecast(data);
			}
		});

	}, []);


	return (
		<div className="App">

			<Background
				isDay={currentWeather.is_day}
				screenOrientation={screenOrientation}
			/>

			<Weather
				icon={currentWeather.condition_icon}
				conditionText={currentWeather.condition_text}
				current={currentWeather.temp_c}
				wind={currentWeather.wind_kph}
				humidity={currentWeather.humidity}
				rain={todayForecast.chance_of_rain}
				updated={currentWeather.last_updated}
			/>

			<Forecast />

			<p className="photo-credit">Foto <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Tomas-Damjanovi%C4%87-100172135020697">Tomas Damjanovic</a></p>
		</div>
	);
}

export default App;
