function importAll(r) {
	return r.keys().map(r);
}

const landscpeDayImages = importAll(require.context('./landscape/day', false, /\.(png|jpe?g|svg)$/));
const landscapeNightImages = importAll(require.context('./landscape/night', false, /\.(png|jpe?g|svg)$/));
const portraitDayImages = importAll(require.context('./portrait/day', false, /\.(png|jpe?g|svg)$/));
const portraitNightImages = importAll(require.context('./portrait/night', false, /\.(png|jpe?g|svg)$/));

export const landscapeImages = {
	day: landscpeDayImages,
	night: landscapeNightImages
};

export const portraitImages = {
	day: portraitDayImages,
	night: portraitNightImages
};

