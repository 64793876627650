import React from 'react';
import { getDayText, getMonthText, getDayInMonth } from '../utility/Utility';

function Day(props) {

	if (!props.dayForecast) {
		return '';
	}

	return (
		<div className={props.dayForecast ? 'day show' : 'day'} title={getDayText(props.dayForecast.date) +', '+ getDayInMonth(props.dayForecast.date) +' '+ getMonthText(props.dayForecast.date)}>
			<p className="name">{getDayText(props.dayForecast.date).substring(0,3)}</p>
			<img className="icon" title={props.dayForecast.condition_text} src={props.dayForecast.condition_icon} alt="113" />
			<p className="temp-max" title="Najviša temperatura u celzijusima">{props.dayForecast.maxtemp_c}</p>
			<p className="temp-min" title="Najniža temperatura u celzijusima">{props.dayForecast.mintemp_c}</p>
		</div>
	);
}

export default Day;
